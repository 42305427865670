import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getQuery } from '../lib/utils/common';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface ErrorPageProps {
  statusCode?: number | null | undefined;
}

/**
 * Rendered for 500 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */
const ErrorPage: NextPage<ErrorPageProps> = ({ statusCode }) => {
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (statusCode === 404) {
      router.push({
        pathname: '/404',
        query: getQuery(query),
      });
    } else if (statusCode === 403) {
      router.push({
        pathname: '/403',
        query: getQuery(query),
      });
    } else if (statusCode === 500) {
      router.push({
        pathname: '/500',
        query: getQuery(query),
      });
    } else if (statusCode === 503) {
      router.push({
        pathname: '/503',
        query: getQuery(query),
      });
    } else if (statusCode === 505) {
      router.push({
        pathname: '/505',
        query: getQuery(query),
      });
    } else {
      router.push({
        pathname: '/errorpage',
        query: getQuery(query),
      });
    }
  }, [statusCode]);
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res?.statusCode : err ? err?.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
